import React from 'react'

import { useTranslation } from '@/lib/i18n'
import { FormControlLabel, FormGroup, Switch, Typography } from '@mui/material'

import { useFeature } from './use-feature'

import { Feature } from '../../const/feature'

export const FeatureSwitch: React.FC<{ feature: Feature }> = ({ feature }) => {
    const { t } = useTranslation('common')
    const { enabled, toggle } = useFeature(feature)

    return (
        <FormGroup>
            <FormControlLabel
                control={<Switch size="small" checked={enabled} onChange={toggle} />}
                label={<Typography variant="body4">{t(`features.${feature}`)}</Typography>}
            />
        </FormGroup>
    )
}
