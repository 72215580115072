'use client'

import { usePathname, useRouter, useSearchParams } from 'next/navigation'

import { useTranslation } from '@/lib/i18n'

import { useLanguage } from '../../../hooks/use-language'
import { Language } from '../../../lib/i18n/i18n'

export const LanguageSwitch = () => {
    const { t } = useTranslation('common')
    const pathName = usePathname()
    const searchParams = useSearchParams()
    const router = useRouter()

    const language = useLanguage()

    const redirectedPathName = (locale: Language): string => {
        if (!pathName) {
            return '/'
        }

        const segments = pathName.split('/')
        segments[1] = locale

        const fullPath = segments.join('/')
        const queryString = searchParams?.toString()

        return queryString ? `${fullPath}?${queryString}` : fullPath
    }

    const changedLanguage: Language = language === 'en' ? 'de' : 'en'

    return (
        <div onClick={() => router.replace(redirectedPathName(changedLanguage))}>
            {t('changeLocaleTo')} {t(changedLanguage)}
        </div>
    )
}
