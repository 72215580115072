import React from 'react'

import { useUserRoles } from '@/lib/auth/use-user-roles'
import { useTranslation } from '@/lib/i18n'
import { Box, Typography } from '@mui/material'

import { Dot } from '../../dot'

export const UserRolesMenu: React.FC = () => {
    const userRoles = useUserRoles()
    const { t } = useTranslation('common')

    if (userRoles.length === 0) {
        return null
    }

    return (
        <Box>
            <Typography variant="body2">{`${t('user')} ${t('roles')}:`}</Typography>
            {Object.values(userRoles).map((role) => (
                <Box key={role} marginLeft={2} display="flex" alignItems="center" gap={0.5}>
                    <Dot />
                    <Typography variant="body3">{t(`enum.Role.${role}`)}</Typography>
                </Box>
            ))}
        </Box>
    )
}
