import { useSession } from './use-session'

import { filterCountryAccessGroups } from '../../const/enum/claim'
import { UsersCountryAccessInfo } from '../../const/enum/country-access-group'

export const useUsersCountryAccessGroups = (): UsersCountryAccessInfo[] => {
    const { session } = useSession()
    const { roles } = session || {}
    return filterCountryAccessGroups(roles || [])
}
