const rootNamespace = 'lmt'

enum LocalStorageNamespaces {
    'features' = 'features',
}

const localStorageNamespace = (namespace: LocalStorageNamespaces | LocalStorageNamespaces[]) =>
    `${rootNamespace}.${Array.isArray(namespace) ? namespace.join('.') : namespace}`

export const LocalStorageNames = {
    features: localStorageNamespace(LocalStorageNamespaces.features),
}
