import React from 'react'

import { Stack } from '@mui/material'

import { CountryAccessMenu } from './country-access-menu'
import { UserRolesMenu } from './user-roles-menu'

export const UserRolesAndCountryAccessMenu: React.FC = () => (
    <Stack padding={2} paddingTop={1} gap={1}>
        <UserRolesMenu />
        <CountryAccessMenu />
    </Stack>
)
