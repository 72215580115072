import { LocalStorageNames } from './local-storage'

import { onClientSide } from '../utils/window'

export enum Feature {
    DeveloperFeatures = 'DeveloperFeatures',
    FormDebug = 'FormDebug',
    LicenceContractForm = 'LicenceContractForm',
}

export const FeaturesConfigDefault = Object.values(Feature).reduce(
    (result, feature) => {
        result[feature] = false

        return result
    },
    {} as Record<Feature, boolean>,
)

export const enableDeveloperFeatures = () => {
    const enabledDeveloperFeatures = {
        ...FeaturesConfigDefault,
        [Feature.DeveloperFeatures]: true,
    }

    window.localStorage.setItem(LocalStorageNames.features, JSON.stringify(enabledDeveloperFeatures))
    window.location.reload()
}

declare global {
    interface Window {
        lmt: {
            enableDeveloperFeatures: typeof enableDeveloperFeatures
        }
    }
}

onClientSide(() => {
    window.lmt = {
        enableDeveloperFeatures,
    }
})
