import { useEffect } from 'react'

import { useLocalStorage } from 'react-use'

import { Feature, FeaturesConfigDefault } from '../../const/feature'
import { LocalStorageNames } from '../../const/local-storage'

export const useFeatures = () => useLocalStorage(LocalStorageNames.features, FeaturesConfigDefault)

export const useFeature = (feature: Feature) => {
    const [features, setFeatures] = useFeatures()
    const enabled = features ? features[feature] : false

    useEffect(() => {
        const currentFeatures = Object.keys(features || {})
        const allFeatures = Object.keys(Feature)

        if (features && currentFeatures.every((f) => allFeatures.includes(f)) && allFeatures.every((f) => currentFeatures.includes(f))) {
            return
        }

        const toBeIncluded = allFeatures.filter((f) => !currentFeatures.includes(f)) as Feature[]
        const toBeExcluded = currentFeatures.filter((f) => !allFeatures.includes(f)) as Feature[]

        const withNewFeatures = { ...features }
        toBeIncluded.forEach((newFeature) => {
            withNewFeatures[newFeature] = false
        })
        toBeExcluded.forEach((removedFeature) => {
            delete withNewFeatures[removedFeature]
        })

        console.info(`features changed: ${JSON.stringify(withNewFeatures, null, 2)}`)
        setFeatures(withNewFeatures as Record<Feature, boolean>)
    }, [features, setFeatures])

    const toggle = () => {
        const result = { ...features, [feature]: !enabled } as Record<Feature, boolean>
        setFeatures(result)
        if (feature === Feature.DeveloperFeatures) {
            window.location.reload()
        }
    }

    return { enabled, features, toggle }
}
